import axiosInstance from '../axios/axiosInstance';
import {
  FacebookAddPageRequest,
  FacebookConnectRequest
} from '../redux/facebookUserInformation/type';

export const getFacebookConnecting = () => {
  return axiosInstance.get('/v1/fb-accounts/connecting');
};

export const connectFacebook = (data: FacebookConnectRequest) => {
  return axiosInstance.post('/v1/fb-accounts/connect', data);
};

export const disconnectFacebook = (id: string) => {
  return axiosInstance.post(`/v1/fb-accounts/disconnect/${id}`);
};

export const getFacebookPages = (params: any) => {
  return axiosInstance.get('/v1/pages', {
    params
  });
};

export const getImportedFacebookPages = (params: any) => {
  return axiosInstance.get('/v1/pages/imported-pages', {
    params
  });
};

export const addFacebookPages = (data: FacebookAddPageRequest) => {
  return axiosInstance.post('/v1/pages', data);
};

export const getDetailFacebookPages = (id: string) => {
  return axiosInstance.get(`/v1/pages/${id}`);
};

export const removeFacebookPages = (id: string) => {
  return axiosInstance.post(`/v1/pages/remove-page/${id}`);
};

export const removeFacebookPagesConversations = (id: string) => {
  return axiosInstance.post(`/v1/pages/delete-page-conversations/${id}`);
};

export const getFacebookConversations = (params: any, pageID: string) => {
  return axiosInstance.get(`/v1/conversations/${pageID}`, {
    params
  });
};

export const syncChattedUser = (pageID: string) => {
  return axiosInstance.post(`/v1/pages/sync-chatted-users/${pageID}`);
};

export const stopSyncChattedUser = (pageID: string) => {
  return axiosInstance.post(`/v1/pages/stop-sync-chatted-users/${pageID}`);
};

export const sendMessage = (pageID: string, body: any) => {
  return axiosInstance.put(`/v1/chatted-users/send-message/${pageID}`, body);
};

export const getListCampains = (params: any, pageID: string) => {
  return axiosInstance.get(`/v1/campaigns/list/${pageID}`, {
    params
  });
};

export const sendMessageAsCampaign = (pageID: string, body: any) => {
  return axiosInstance.put(`/v1/campaigns/add-campaign/${pageID}`, body);
};
