import axiosInstance from '../axios/axiosInstance';
import { RequestConfigBody } from '../pages/General/SettingComponent';

export const updateSetting = (data: RequestConfigBody) => {
  return axiosInstance.post('/v1/settings/update-config', data);
};

export const getSetting = () => {
  return axiosInstance.get('/v1/settings');
};

export const getStatisticMessage = (id: string) => {
  return axiosInstance.get(`/v1/statistics/message/${id}`);
};

export const getBlocked = (id: string) => {
  return axiosInstance.get(`/v1/statistics/blocked-list/${id}`);
};

export function uploadFile(formData: any) {
  return axiosInstance.post('/v1/campaigns/upload-file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
