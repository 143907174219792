/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useState } from 'react';
import { Form, InputNumber, message, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { validatePositiveNumber } from '../../utils/validatePositiveNumber';
import { RequestConfigBody } from './SettingComponent';
import { updateSetting } from '../../services/generalService';
import { errorMapping } from '../../utils/errorMapping';
import { formatLocaleNumber } from '../../utils/formatLocaleNumber';

const UpdateSettingComponent: React.FC<any> = ({
  editSettingState,
  setEditSettingState,
  fetchSetting
}) => {
  const [updateUserForm] = Form.useForm();
  const [isSavingInitial, setIsSavingInitial] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<number>(0);

  const handleOk = () => {
    updateUserForm.submit();
  };

  const handleCancel = () => {
    updateUserForm.resetFields();
    setEditSettingState(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      updateUserForm.submit();
    }
  };

  useEffect(() => {
    if (editSettingState?.id) {
      updateUserForm.setFieldValue('initial', editSettingState?.value);
      setInputValue(+editSettingState?.value);
    } else {
      updateUserForm.resetFields();
    }
  }, [editSettingState]);

  const updateInitial = async (data: any) => {
    if (!editSettingState?.id) {
      return;
    }
    try {
      setIsSavingInitial(true);
      const savingData: RequestConfigBody = {
        key: editSettingState?.key,
        value: data.toString()
      };
      await updateSetting(savingData);
      fetchSetting();
      setEditSettingState(null);
      message.success('Update successfully!');
      setIsSavingInitial(false);
    } catch (error: any) {
      errorMapping(error);
      setIsSavingInitial(false);
    }
  };

  const onFinish = async (values: any) => {
    updateInitial(values?.initial);
  };

  const suffixRender = (): ReactNode => {
    switch (editSettingState?.key) {
      case 'DELAY_FETCH_USER':
        if (!inputValue) {
          return '';
        }
        return `= ${formatLocaleNumber(Number(inputValue) / 1000)} seconds`;
      case 'BATCH_SEND_MESSAGE':
        return `messages / batch`;
      case 'BATCH_FETCH_USER':
        return `users / batch`;
      default:
        if (!inputValue) {
          return '';
        }
        return `= ${formatLocaleNumber(Number(inputValue) / 1000)} seconds`;
    }
  };

  console.log(editSettingState?.key);

  return (
    <>
      <Modal
        title="Setting"
        open={editSettingState?.id}
        okText="Save"
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isSavingInitial
        }}
      >
        <Form
          name="inittial"
          layout="vertical"
          className="mt-5"
          form={updateUserForm}
          onFinish={onFinish}
          disabled={isSavingInitial}
        >
          <Form.Item
            name="initial"
            rules={[
              {
                required: true,
                message: 'Please input Initial!'
              },
              {
                validator: validatePositiveNumber
              }
            ]}
          >
            <InputNumber
              className="w-full"
              prefix={isSavingInitial && <LoadingOutlined />}
              placeholder="Amount"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) =>
                value?.replace(/\$\s?|(,*)/g, '') as unknown as number
              }
              value={inputValue}
              onChange={(e) => {
                console.log(e);
                setInputValue(e as number);
              }}
              onKeyDown={handleKeyDown}
              suffix={suffixRender()}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateSettingComponent;
