export const ERROR_MESSAGE = 'Something went wrong. Try again later';

export const DEFAULT_DATETIME_FORMAT = 'HH:mm:ss DD-MM-YYYY';

export const DEFAULT_PAGINATE_SIZE = 10;

export const DEFAULT_PASSWORD = 'Abc1234@';

export const ACCEPTED_FILE_TYPES = {
  IMAGE: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
  AUDIO: ['audio/mpeg', 'audio/mp3', 'audio/wav'],
  VIDEO: [
    'video/mp4',
    'video/mkv',
    'video/mov',
    'video/quicktime',
    'video/x-matroska'
  ],
  FILE: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv'
  ]
};

export enum FileTypes {
  TEXT = 'text',
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  FILE = 'file'
}
export const MIME_TO_EXTENSION: Record<string, string> = {
  'image/png': '.png',
  'image/jpeg': '.jpeg',
  'image/jpg': '.jpg',
  'image/gif': '.gif',
  'audio/mpeg': '.mp3',
  'audio/mp3': '.mp3',
  'audio/wav': '.wav',
  'video/mp4': '.mp4',
  'video/mkv': '.mkv',
  'video/mov': '.mov',
  'video/quicktime': '.mov',
  'video/x-matroska': '.mkv',
  'application/pdf': '.pdf',
  'application/msword': '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    '.docx',
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'text/csv': '.csv'
};
