/* eslint-disable @typescript-eslint/no-unused-expressions */

import React, { useState } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from 'chart.js';
import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getBlocked } from '../../services/generalService';
import { errorMapping } from '../../utils/errorMapping';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const DownloadBlockedUser: React.FC<any> = ({ uuid }) => {
  const [loading, setLoading] = useState(false);
  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await getBlocked(uuid);
      const url = response.data.data;
      if (url) {
        // downloadFunc(url);
        window.open(url, '_blank');
      } else {
        message.warning('No blocked user found!');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorMapping(error);
    }
  };

  // const downloadFunc = (url: string) => {
  //   try {
  //     fetch(url)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const url = window.URL.createObjectURL(new Blob([blob]));
  //         const a = document.createElement('a');
  //         a.href = url;
  //         const time = moment().format('DD-MM-YYYY-hh:mm:ss');
  //         a.download = `block-users-${time}.xlsx`;
  //         a.click();
  //         window.URL.revokeObjectURL(url);
  //       })
  //       .catch((error) => console.error('Error download:', error));
  //   } catch (error) {
  //     console.error('Unexpected error:', error);
  //   }
  // };

  return (
    <Button
      onClick={handleDownload}
      loading={loading}
      icon={<DownloadOutlined />}
      type="primary"
    >
      Download Excel File
    </Button>
  );
};

export default DownloadBlockedUser;
