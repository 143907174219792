import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginComponent from '../pages/Login/LoginComponent';
import PublicRoute from './PublicRoute';
import MainComponent from '../pages/MainComponent/MainComponent';
import GuardRoute from './GuardRoute';
import UserManageComponet from '../pages/UserManage/UserManage';
import FacebookPageManageComponent from '../pages/FaceBookPageManage/FacebookPageManage';
import BoardingMessageManage from '../pages/BoardingMessageManage/BoardingMessageManage';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import PrivacyPolicyComponent from '../pages/PrivacyPolicy/PrivacyPolicyComponent';
import SettingComponent from '../pages/General/SettingComponent';
import TestLoginComponent from '../pages/MainComponent/TestLoginComponent';

const AppRoutersComponent = () => {
  const { user, loading, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  if (loading || (!user && isAuthenticated)) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route
        path="/login"
        element={<PublicRoute element={<LoginComponent />} />}
      />

      <Route path="/privacy-policy" element={<PrivacyPolicyComponent />} />
      <Route path="/connect-facebook" element={<TestLoginComponent />} />

      <Route path="/connect-facebook" element={<TestLoginComponent />} />

      <Route
        path="/main"
        element={
          <GuardRoute
            hasPermission={user?.roles[0].role_tag === 'page_manager'}
            element={<MainComponent />}
          />
        }
      />

      <Route
        path="/setting"
        element={
          <GuardRoute
            hasPermission={
              !!user?.roles[0].role_tag &&
              user?.roles[0].role_tag !== 'page_manager'
            }
            element={<SettingComponent />}
          />
        }
      />

      <Route
        path="/account"
        element={
          <GuardRoute
            hasPermission={
              !!user?.roles[0].role_tag &&
              user?.roles[0].role_tag !== 'page_manager'
            }
            element={<UserManageComponet />}
          />
        }
      />

      <Route
        path="/facebook-page"
        element={<GuardRoute element={<FacebookPageManageComponent />} />}
      />
      <Route
        path="/broading-messages"
        element={<GuardRoute element={<BoardingMessageManage />} />}
      />

      <Route
        path="*"
        element={
          <GuardRoute
            element={
              user?.roles[0].role_tag === 'page_manager' ? (
                <MainComponent />
              ) : (
                <UserManageComponet />
              )
            }
          />
        }
      />
    </Routes>
  );
};

export default AppRoutersComponent;
