/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { changePassword } from '../../services/authenServices';
import { correctIcon, incorrectIcon } from '../../constants/svg';
import { ERROR_MESSAGE } from '../../constants/common';

function checkRegexMatch(inputString, regexPattern) {
  const regex = new RegExp(regexPattern);
  return regex.test(inputString);
}

function checkValidate(type, password, retypePassword) {
  if (type === 'upperCase') {
    const uppercaseRegex = /^(?=.*[A-Z])/;
    if (checkRegexMatch(password, uppercaseRegex)) return true;
  }
  if (type === 'special') {
    const specialCharacterRegex = /^(?=.*[!@#$%^&*()_+{}\\[\]:;<>,.?~])/;
    if (checkRegexMatch(password, specialCharacterRegex)) return true;
  }
  if (type === 'digit') {
    const digitRegex = /^(?=.*\d)/;
    if (checkRegexMatch(password, digitRegex)) return true;
  }
  if (type === 'minLength') {
    const minLengthRegex = /^.{8,}$/;
    if (checkRegexMatch(password, minLengthRegex)) return true;
  }
  return false;
}

const getValidateIcon = (type, password, retypePassword) => {
  if (checkValidate(type, password, retypePassword)) return correctIcon;
  return incorrectIcon;
};

const ModalChangeAndForgotPassword = ({ isOpen, setIsOpen }) => {
  const [form] = Form.useForm();
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [isValidate, setIsValidate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      setIsValidate(false);
    }
    // else {
    //   setDataForgotPassword(null)
    // }
  }, [isOpen]);

  const allValidate = useMemo(
    () =>
      checkValidate('upperCase', password, retypePassword) &&
      checkValidate('special', password, retypePassword) &&
      checkValidate('digit', password, retypePassword) &&
      checkValidate('minLength', password, retypePassword) &&
      password === retypePassword,
    [password, retypePassword]
  );

  const handleClickUpdate = async () => {
    if (allValidate) {
      try {
        await changePassword(
          {
            new_password: password,
            confirm_password: retypePassword
          },
          isOpen
        );
        setIsOpen(false);
        Modal.success({
          title: 'Success',
          content: 'Password changed successfully',
          centered: true,
          okButtonProps: {
            style: {
              backgroundColor: 'transparent',
              color: 'black',
              borderColor: 'rgba(232, 232, 232, 1)',
              marginTop: 15
            }
          },
          onOk: () => {
            setIsOpen(false);
          },
          onCancel: () => {
            setIsOpen(false);
          }
        });
      } catch (e) {
        const errorMessage = e?.response?.data?.message;
        let error = ERROR_MESSAGE;
        if (errorMessage?.includes('E10139')) {
          error = 'The current password is incorrect. Please re-enter';
        }
        Modal.error({
          title: 'Password update failed',
          content: error,
          centered: true,
          okButtonProps: {
            style: {
              backgroundColor: 'transparent',
              color: 'black',
              borderColor: 'rgba(232, 232, 232, 1)',
              marginTop: 15
            }
          }
        });
      }
    }
  };

  const onHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'password') {
      setPassword(value);
    }
    if (name === 'retype_password') {
      form.validateFields(['retype_password']);
      setRetypePassword(value);
    }
  };

  return (
    <Modal
      centered
      open={!!isOpen}
      wrapClassName="modal-change-password"
      style={{ width: 576 }}
      footer={null}
      closable
      onCancel={() => setIsOpen(false)}
    >
      <div className="modal-content">
        <div className="mcp-header">Change new password</div>
        <div className="mcp-sub-header">
          Please enter a new password, remember it and do not disclose it to
          anyone.
        </div>
        <div className="mcp-form">
          <Form
            onChange={() => setIsValidate(true)}
            layout="vertical"
            form={form}
            onFinish={handleClickUpdate}
          >
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter new password'
                }
              ]}
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                name="password"
                placeholder="Enter new password"
                onChange={onHandleChange}
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="retype_password"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (password === retypePassword) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Passwords do not match');
                  }
                })
              ]}
            >
              {' '}
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                name="retype_password"
                placeholder="Re-enter new password"
                onChange={onHandleChange}
              />
            </Form.Item>
          </Form>
          <div>
            <div className="text-checklist">Password must include:</div>
            <ul className={`cp-checklist ${isValidate ? 'remove-stype' : ''}`}>
              <li>
                {isValidate && (
                  <>{getValidateIcon('upperCase', password, retypePassword)}</>
                )}
                At least one uppercase character
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('special', password, retypePassword)}</>
                )}
                At least one special character
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('digit', password, retypePassword)}</>
                )}
                At least one digit
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('minLength', password, retypePassword)}</>
                )}
                Minimum 8 characters
              </li>
            </ul>
          </div>
          <div className="btn-update-cont">
            <Button
              disabled={!allValidate}
              size="large"
              className="w-full"
              onClick={() => form.submit()}
              type="primary"
            >
              Update Password
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ModalChangeAndForgotPassword;
