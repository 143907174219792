/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';
import usePaginationConfig from '../../hooks/usePaginationConfig';
import useSortOrder from '../../hooks/useSortOrder';
import { Button, Input, message, Modal, Table } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import dateTimeUTCConverter from '../../utils/dateTimeUTCConverter';
import UserItem, { IUserItem } from './userItem';
import { DEFAULT_PAGINATE_SIZE } from '../../constants/common';
import {
  getFacebookConversations,
  removeFacebookPagesConversations
} from '../../services/facebookServices';
import {
  ExclamationCircleTwoTone,
  SendOutlined,
  SyncOutlined
} from '@ant-design/icons';
import SendMessageComponent from './SendMessageComponent';
import useDebounce from '../../hooks/useDebounce';
import { errorMapping } from '../../utils/errorMapping';
import Delete from '../../assets/svg/delete';

const { Search } = Input;
const FacebookConversationList: React.FC<any> = ({
  uuid,
  handleSyncChattedUser,
  syncing
}) => {
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sendMessageState, setSendMessageState] = useState<any>(null);
  const paginationConfig = usePaginationConfig(total, page, setPage);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Set<string | number>>(
    new Set([])
  );
  const [userIds, setUserIds] = useState<Set<string | number>>(new Set([]));

  const [keyword, setKeyword] = useState('');

  const [actionState, setActionState] = useState<any>({
    isOpen: false,
    text: '',
    isLoading: false
  });

  /* eslint-disable camelcase */
  const search_text = useDebounce(keyword, 300);

  const { sortOrder, sortColumns, updateSortOrder, getSortParams } =
    useSortOrder<any>();

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    updateSortOrder(sorter);
  };

  const handleDelete = async (id: string) => {
    try {
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: true
      }));
      await removeFacebookPagesConversations(id);
      message.success('Delete successfully!');
      setActionState({
        isOpen: false,
        text: '',
        isLoading: false
      });
      fetchFacebookConversation();
    } catch (err) {
      console.log(err);
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
      errorMapping(err);
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'conversationFbId',
      key: 'conversationFbId',
      align: 'center'
      // ...sortColumns('chainId'),
    },
    {
      title: 'Updated Time',
      dataIndex: 'updatedTime',
      align: 'center',
      key: 'updatedTime',
      ...sortColumns('updatedTime'),
      render: (value: string) => dateTimeUTCConverter(value)
    },
    {
      title: 'User',
      dataIndex: 'chattedUsers',
      align: 'center',
      key: 'chattedUsers',
      render: (chattedUsers: IUserItem[]) => (
        <>
          {(chattedUsers || []).map((user, index) => (
            <React.Fragment key={user.id}>
              <UserItem item={user} />
            </React.Fragment>
          ))}
        </>
      )
    }
  ];

  const fetchFacebookConversation = async () => {
    try {
      setLoading(true);
      const res = await getFacebookConversations(
        {
          page,
          limit: DEFAULT_PAGINATE_SIZE,
          order_by: 'updatedTime',
          sort_by: 'desc',
          search_text,
          ...getSortParams()
        },
        uuid
      );
      const conversation = res?.data?.data;
      const itemsWithKey = (conversation?.items || []).map((item: any) => ({
        ...item,
        key: item?.id,
        idUser: (item?.chattedUsers || []).map((user: any) => user.id)
      }));
      setData(itemsWithKey);
      setTotal(conversation?.meta?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorMapping(error);
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchFacebookConversation();
    }
  }, [page, sortOrder, search_text]);

  const rowSelection = {
    selectedRowKeys: Array.from(selectedRowKeys),
    onSelect: (record: any, selected: any) => {
      if (selected) {
        addItem(record?.key);
        addUserItem(record?.idUser);
      } else {
        removeItem(record?.key);
        removeUserItem(record?.idUser);
      }
    },
    hideSelectAll: true
  };

  const addItem = (item: string | number) => {
    setSelectedRowKeys((prevItems) => {
      const newItems = new Set(prevItems);
      newItems.add(item);
      return newItems;
    });
  };

  const removeItem = (item: string | number) => {
    setSelectedRowKeys((prevItems) => {
      const newItems = new Set(prevItems);
      newItems.delete(item);
      return newItems;
    });
  };

  const addUserItem = (items: (string | number)[]) => {
    setUserIds((prevItems) => {
      const newItems = new Set(prevItems);
      items.forEach((item) => newItems.add(item));
      return newItems;
    });
  };

  const removeUserItem = (items: (string | number)[]) => {
    setUserIds((prevItems) => {
      const newItems = new Set(prevItems);
      items.forEach((item) => newItems.delete(item));
      return newItems;
    });
  };

  return (
    <div>
      <div className="mb-5 flex gap-3">
        {/* <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={() => {
            setSendMessageState({
              id: uuid,
              total
            });
          }}
        >
          Send message to all user
        </Button> */}
        <Button
          disabled={!selectedRowKeys?.size}
          icon={<SendOutlined />}
          type="primary"
          onClick={() => {
            setSendMessageState({
              id: uuid,
              ids: Array.from(userIds)
            });
          }}
        >
          Send message to selected User ({selectedRowKeys?.size})
        </Button>
        <Button
          icon={<Delete />}
          type="primary"
          danger
          onClick={() => {
            setActionState({
              isOpen: true,
              text: 'delete',
              isLoading: false,
              id: uuid
            });
          }}
        >
          Delete all users
        </Button>
        {!!selectedRowKeys?.size && (
          <Button
            type="primary"
            onClick={() => {
              setSelectedRowKeys(new Set([]));
              setUserIds(new Set([]));
            }}
            danger
          >
            Clear
          </Button>
        )}
        <Button
          onClick={() => {
            handleSyncChattedUser(false);
          }}
          disabled={syncing}
          icon={<SyncOutlined spin={syncing} />}
        >
          Sync User
        </Button>
      </div>
      <Search
        className="mb-3 mt-2"
        placeholder="Search here"
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
        style={{ width: 300 }}
        enterButton
      />
      <Table
        className="mt-3"
        columns={columns as any}
        dataSource={data}
        loading={loading}
        bordered
        onChange={handleTableChange}
        pagination={paginationConfig}
        rowClassName={() => 'cursor-pointer'}
        rowSelection={rowSelection}
        //   onRow={(record, rowIndex) => ({
        //     onClick: () => {
        //       console.log(record, rowIndex);
        //     }
        //   })}
      />
      <SendMessageComponent
        sendMessageState={sendMessageState}
        setSendMessageState={setSendMessageState}
        setSelectedRowKeys={setSelectedRowKeys}
        setUserIds={setUserIds}
      />

      <Modal
        closable={!actionState?.isLoading}
        maskClosable={!actionState?.isLoading}
        closeIcon={actionState?.isLoading}
        title={
          <div className="flex gap-3">
            <ExclamationCircleTwoTone style={{ fontSize: 20 }} />
            {actionState?.text.toLocaleUpperCase()}
          </div>
        }
        open={actionState?.isOpen}
        onOk={() => {
          switch (actionState?.text) {
            case 'delete':
              handleDelete(actionState?.id);
              break;
            default:
              handleDelete(actionState?.id);
              break;
          }
        }}
        confirmLoading={actionState?.isLoading}
        cancelButtonProps={{
          disabled: actionState?.isLoading
        }}
        onCancel={() => {
          if (actionState?.isLoading) return;
          setActionState({
            isOpen: false,
            text: '',
            isLoading: false
          });
        }}
        okText="Confirm"
      >
        <p>{`Do you want to ${actionState?.text} all users?`}</p>
      </Modal>
    </div>
  );
};

export default FacebookConversationList;
