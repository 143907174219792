import React, { useCallback, useEffect, useState } from 'react';
import { Header } from 'antd/es/layout/layout';
import { Avatar, Dropdown, MenuProps, Modal } from 'antd';
import {
  ExclamationCircleFilled,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout, setUser } from '../../redux/auth/authSlice';
import { getProfile } from '../../services/authenServices';

import {
  fetchFaceBookInformation,
  setFacebookUserInformation
} from '../../redux/facebookUserInformation/facebookUserInformationSlice';
import { User } from '../../redux/auth/type';
import LoadingComponent from '../../components/LoadingComponent';
import CaretDown from '../../assets/svg/caretDown';
import Title from 'antd/es/typography/Title';
import { errorMapping } from '../../utils/errorMapping';

const HeaderComponent: React.FC = () => {
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const { faceBookUserInformation } = useSelector(
    (state: RootState) => state.facebookUserInformation
  );

  const [fetchingUser, setFetchingUser] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isPrivacyPolicy = location.pathname === '/privacy-policy';

  const appLogout = useCallback(() => {
    Modal.confirm({
      title: 'Logout',
      icon: <ExclamationCircleFilled />,
      content: 'Do you want to logout?',
      onOk() {
        dispatch(setFacebookUserInformation(null));
        dispatch(logout());
        navigate('/login');
      }
    });
  }, [dispatch, navigate]);

  useEffect(() => {
    if (isAuthenticated && !user) {
      const fetchUser = async () => {
        try {
          setFetchingUser(true);
          const user = await getProfile();
          dispatch(setUser(user?.data?.data as User));
          setFetchingUser(false);
        } catch (error) {
          setFetchingUser(false);
          errorMapping(error);
          appLogout();
        }
      };
      fetchUser();
    }
  }, [isAuthenticated, user, appLogout, dispatch]);

  useEffect(() => {
    if (isAuthenticated && !faceBookUserInformation) {
      dispatch(fetchFaceBookInformation(navigate) as any);
    }
  }, [isAuthenticated, faceBookUserInformation, appLogout, dispatch]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className="h-6 w-24 text-center font-semibold">
          {user?.username || 'user'}
        </div>
      )
    },
    {
      key: '4',
      danger: true,
      label: 'Logout',
      icon: <LogoutOutlined />,
      onClick: appLogout
    }
  ];
  return (
    <Header className="header !bg-[#F5F5F5]">
      <div
        className={`w-full mx-6 flex justify-between items-center ${isPrivacyPolicy ? 'container mx-auto' : ''}`}
      >
        <div
          className={`logo-container ${isPrivacyPolicy ? '' : 'ml-[10px]'}`}
          onClick={() => {
            if (isPrivacyPolicy && !isAuthenticated) {
              return;
            }
            navigate('/account');
          }}
        >
          <img
            className="logo-img cursor-pointer max-sm:!h-[60px]"
            src="/images/broadcast.png"
          />
          <Title level={2} className="!mb-0">
            CUSTOMER CARE
          </Title>
        </div>
        {isAuthenticated && (
          <div className="flex gap-4 items-center">
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              className="header-dropdown"
            >
              <div className="flex gap-[10px] cursor-pointer items-center leading-0">
                <Avatar size={38} icon={<UserOutlined />} />
                <div className="user-name max-sm:hidden">
                  {user?.username || 'user'}
                </div>
                <CaretDown />
              </div>
            </Dropdown>
          </div>
        )}
        <LoadingComponent fullscreen loading={fetchingUser} />
      </div>
    </Header>
  );
};

export default HeaderComponent;
