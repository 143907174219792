import React from 'react';
import { Layout, ConfigProvider, ThemeConfig, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import './App.scss';
import SiderComponent from './pages/Common/SiderComponent';
import AppRoutersComponent from './routers/AppRoutersComponent';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { setupInterceptors } from './axios/axiosInstance';
import HeaderComponent from './pages/Common/HeaderComponent';

message.config({
  // top: 100,
  // duration: 2,
  maxCount: 1
  // rtl: true,
  // prefixCls: 'my-message',
});

const customTheme: ThemeConfig = {
  token: {
    colorBgContainer: '#fff',
    borderRadiusLG: 10
  }
};

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Thiết lập interceptors khi load
  React.useEffect(() => {
    setupInterceptors(store, navigate);
  }, [navigate]);

  const isPrivacyPolicy = location.pathname === '/privacy-policy';

  return (
    <Provider store={store}>
      <ConfigProvider theme={customTheme}>
        <HeaderComponent />
        <Layout
          className={`${isPrivacyPolicy ? '' : 'h-[calc(100vh-80px)] overflow-auto'} `}
        >
          {!isPrivacyPolicy && <SiderComponent />}
          <Layout className="bg-content">
            <Content
              className={`${isPrivacyPolicy ? '' : 'my-4 mx-6 p-6 h-full overflow-auto'} `}
            >
              <AppRoutersComponent />
            </Content>
          </Layout>
        </Layout>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
