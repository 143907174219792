import {
  ACCEPTED_FILE_TYPES,
  FileTypes,
  MIME_TO_EXTENSION
} from '../constants/common';

export const getAllowedExtensions = (types: FileTypes[]): string => {
  const mimeTypes = types
    .flatMap(
      (type) =>
        ACCEPTED_FILE_TYPES[
          type.toUpperCase() as keyof typeof ACCEPTED_FILE_TYPES
        ] || []
    )
    .filter((mime, index, self) => self.indexOf(mime) === index);

  const extensions = Array.from(
    new Set(
      mimeTypes
        .map((mime) => MIME_TO_EXTENSION[mime] || '')
        .filter((ext) => ext)
    )
  );
  return extensions.join(', ');
};
