import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';

interface ElementWithTooltipComponentProps {
  tooltip?: string;
  children?: ReactNode;
  className?: string;
}

const ElementWithTooltipComponent: React.FC<
  ElementWithTooltipComponentProps
> = ({ children, className = '', tooltip = '' }) => {
  return (
    <div className={` ${className}`}>
      <Tooltip title={tooltip} placement="bottom">
        {children}
      </Tooltip>
    </div>
  );
};

export default ElementWithTooltipComponent;
