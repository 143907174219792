/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Button, message, Modal, Table } from 'antd';
import { DEFAULT_PAGINATE_SIZE } from '../../constants/common';
import { SorterResult } from 'antd/es/table/interface';
import {
  addFacebookPages,
  getFacebookPages
} from '../../services/facebookServices';
import useSortOrder from '../../hooks/useSortOrder';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import OpenSvg from '../../assets/svg/open';
import { errorMapping } from '../../utils/errorMapping';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  VerticalAlignBottomOutlined
} from '@ant-design/icons';

const AddFacebookPageComponent = ({
  isModalOpen,
  setIsModalOpen
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}) => {
  const [isSavingInitial, setIsSavingInitial] = useState<boolean>(false);
  const [cursors, setCursors] = useState({ before: null, after: null });
  const [currentPage, setCurrentPage] = useState(1);
  const { faceBookUserInformation } = useSelector(
    (state: RootState) => state.facebookUserInformation
  );

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addPages = async (data: any) => {
    try {
      setIsSavingInitial(true);
      const body = {
        accessToken: data?.access_token,
        pageId: data?.id,
        fbId: faceBookUserInformation?.fbId,
        name: data?.name,
        category: data?.category,
        pictureUrl: data?.picture?.data?.url,
        linkUrl: data?.link,
        fanCount: data?.fan_count
      };
      await addFacebookPages(body);
      setIsModalOpen(false);
      message.success('Add page successfully!');
      setIsSavingInitial(false);
    } catch (error: any) {
      if (error?.response?.data?.message.includes('E10003')) {
        message.error('User already exists!');
      } else {
        errorMapping(error);
      }
      setIsSavingInitial(false);
    }
  };

  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const totalPages = useMemo(() => {
    if (!total) return 0;
    return Math.ceil(total / DEFAULT_PAGINATE_SIZE);
  }, [total]);
  // const paginationConfig = usePaginationConfig(total, page, setPage);
  const [fetching, setFetching] = useState<boolean>(false);

  const { updateSortOrder } = useSortOrder<any>();

  useEffect(() => {
    if (isModalOpen) {
      setCurrentPage(1);
      setTotal(0);
    }
  }, [isModalOpen]);

  const fetchProject = async (direction?: 'next' | 'previous') => {
    let after = null;
    let before = null;

    if (direction === 'next' && cursors.after) {
      after = cursors.after;
    } else if (direction === 'previous' && cursors.before) {
      before = cursors.before;
    }

    if (direction === 'next' && currentPage === totalPages) {
      after = null;
    }

    if (direction === 'previous' && currentPage === 1) {
      before = null;
    }
    try {
      setFetching(true);
      const res = await getFacebookPages({
        limit: DEFAULT_PAGINATE_SIZE,
        after,
        before
      });
      const projects = res?.data?.data;
      console.log(res?.data?.data?.data, 123);

      const items = (projects?.data || []).map((item: any) => ({
        ...item,
        pictureUrl: item?.picture?.data?.url
      }));

      setData(items);
      setTotal(projects?.summary?.total_count);
      setCursors({
        before: projects?.paging?.cursors?.before,
        after: projects?.paging?.cursors?.after
      });
      setFetching(false);
    } catch (error) {
      setFetching(false);
      errorMapping(error);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchProject();
    }
  }, [isModalOpen]);

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    updateSortOrder(sorter);
  };

  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'pictureUrl',
      key: 'pictureUrl',
      align: 'center',
      render: (value: string) => (
        <div className="flex justify-center">
          <Avatar size={35} src={value || ''} />
        </div>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      key: 'name'
      // ...sortColumns('chainId'),
    },
    {
      title: 'Link',
      align: 'center',
      dataIndex: 'link',
      key: 'link',
      // ...sortColumns('createdAt'),
      render: (value: string) => (
        <div
          className="flex w-full justify-center"
          onClick={(event) => {
            event.stopPropagation();
            window.open(value, '_blank', 'noopener,noreferrer');
          }}
        >
          <OpenSvg />
        </div>
      )
    },
    {
      title: '',
      align: 'center',
      dataIndex: 'link',
      key: 'link',
      // ...sortColumns('createdAt'),
      render: (value: string, record: any) => (
        <Button
          icon={<VerticalAlignBottomOutlined />}
          type="primary"
          onClick={() => addPages(record)}
        >
          Import
        </Button>
      )
    }
  ];

  const handleNext = () => {
    fetchProject('next');
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    fetchProject('previous');
    setCurrentPage(currentPage - 1);
  };

  return (
    <>
      <Modal
        footer={null}
        title="Add page"
        open={isModalOpen}
        onCancel={handleCancel}
        width={900}
        okButtonProps={{
          loading: isSavingInitial
        }}
      >
        <Table
          columns={columns as any}
          dataSource={data}
          loading={fetching || isSavingInitial}
          bordered
          onChange={handleTableChange}
          // pagination={paginationConfig}
          rowClassName={() => 'cursor-pointer'}
          pagination={false}
          // onRow={(record) => {
          //   return {
          //     onClick: () => {}
          //   };
          // }}
        />
        <div className="mt-5 flex gap-2 items-center justify-center">
          <Button
            icon={<CaretLeftOutlined />}
            type="primary"
            onClick={handlePrevious}
            disabled={currentPage === 1 || isSavingInitial}
            style={{ marginRight: 8 }}
            loading={fetching}
          >
            Previous
          </Button>
          {/* <div className="mr-2">Current Page: {currentPage}</div> */}

          <Button
            icon={<CaretRightOutlined />}
            type="primary"
            onClick={handleNext}
            loading={fetching}
            disabled={currentPage === totalPages || isSavingInitial}
          >
            Next
          </Button>
        </div>

        <div className="mt-5 text-center">Total pages: {total}</div>
      </Modal>
    </>
  );
};

export default AddFacebookPageComponent;
