import { message } from 'antd';
import { ERROR_MESSAGE } from '../constants/common';

export const errorMapping = (error: any): void => {
  const errorMessage = error?.response?.data?.message;
  if (typeof errorMessage === 'string' && errorMessage.length > 3) {
    message.error(errorMessage);
  } else {
    message.error(ERROR_MESSAGE);
  }
};
