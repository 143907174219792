export const correctIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0003 1.66602C5.40033 1.66602 1.66699 5.39935 1.66699 9.99935C1.66699 14.5993 5.40033 18.3327 10.0003 18.3327C14.6003 18.3327 18.3337 14.5993 18.3337 9.99935C18.3337 5.39935 14.6003 1.66602 10.0003 1.66602ZM7.74199 13.5743L4.75033 10.5827C4.42533 10.2577 4.42533 9.73268 4.75033 9.40768C5.07533 9.08268 5.60033 9.08268 5.92533 9.40768L8.33366 11.8077L14.067 6.07435C14.392 5.74935 14.917 5.74935 15.242 6.07435C15.567 6.39935 15.567 6.92435 15.242 7.24935L8.91699 13.5743C8.60033 13.8993 8.06699 13.8993 7.74199 13.5743Z"
      fill="#00BA34"
    />
  </svg>
);

export const incorrectIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0003 1.66602C5.39199 1.66602 1.66699 5.39102 1.66699 9.99935C1.66699 14.6077 5.39199 18.3327 10.0003 18.3327C14.6087 18.3327 18.3337 14.6077 18.3337 9.99935C18.3337 5.39102 14.6087 1.66602 10.0003 1.66602ZM13.5837 13.5827C13.2587 13.9077 12.7337 13.9077 12.4087 13.5827L10.0003 11.1743L7.59199 13.5827C7.26699 13.9077 6.74199 13.9077 6.41699 13.5827C6.09199 13.2577 6.09199 12.7327 6.41699 12.4077L8.82533 9.99935L6.41699 7.59102C6.09199 7.26602 6.09199 6.74102 6.41699 6.41602C6.74199 6.09102 7.26699 6.09102 7.59199 6.41602L10.0003 8.82435L12.4087 6.41602C12.7337 6.09102 13.2587 6.09102 13.5837 6.41602C13.9087 6.74102 13.9087 7.26602 13.5837 7.59102L11.1753 9.99935L13.5837 12.4077C13.9003 12.7243 13.9003 13.2577 13.5837 13.5827Z"
      fill="#E92C2C"
    />
  </svg>
);
