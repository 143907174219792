/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Card, message, Table } from 'antd';
import { getSetting } from '../../services/generalService';
import Title from 'antd/es/typography/Title';
import EditPencil from '../../assets/svg/editPencil';
import UpdateSettingComponent from './UpdateSettingComponent';
import { errorMapping } from '../../utils/errorMapping';
import { formatLocaleNumber } from '../../utils/formatLocaleNumber';

export interface RequestConfigBody {
  key: string;
  value: string;
}

interface SettingData {
  created_by: null;
  updated_by: null;
  deleted_by: null;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  id: string;
  key: string;
  value: string;
}

const SettingComponent: React.FC<any> = () => {
  const [data, setData] = useState<SettingData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editSettingState, setEditSettingState] = useState<any>(null);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'key',
      key: 'key',
      align: 'center'
    },
    {
      title: 'Setting',
      dataIndex: 'value',
      align: 'center',
      key: 'value',
      render: (value: string, record: any) => (
        <div className="flex gap-2 items-center justify-center">
          {suffixRender(+value, record.key)}
          <div
            onClick={() => {
              setEditSettingState({
                id: record?.id,
                ...record
              });
            }}
            className="h-[21px] w-[21px] flex items-center justify-center rounded-[4px] border border-dashed border-[#0CA7EE]"
          >
            <EditPencil />
          </div>
        </div>
      )
    }
  ];

  const fetchSetting = async () => {
    try {
      setLoading(true);
      const res = await getSetting();
      const mappingData = res?.data?.data;
      setData(mappingData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorMapping(error);
    }
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  const suffixRender = (inputValue: number, key: string) => {
    if (!inputValue) {
      return '-';
    }
    switch (key) {
      case 'DELAY_FETCH_USER':
        return `${formatLocaleNumber(inputValue)} milliseconds (${Number(inputValue) / 1000} seconds)`;
      case 'BATCH_SEND_MESSAGE':
        return `${formatLocaleNumber(inputValue)} messages / batch`;
      case 'BATCH_FETCH_USER':
        return `${formatLocaleNumber(inputValue)} users / batch`;
      default:
        return `${formatLocaleNumber(inputValue)} milliseconds (${Number(inputValue) / 1000} seconds)`;
    }
  };

  return (
    <Card>
      <div className="flex justify-between gap-3 items-center mb-4">
        <Title className="leading-none !mb-0 text-dark max-sm:w-full" level={3}>
          All Setting
        </Title>
      </div>
      <Table
        className="mt-3"
        columns={columns as any}
        dataSource={data}
        loading={loading}
        bordered
        rowClassName={() => 'cursor-pointer'}
        pagination={false}
      />
      <UpdateSettingComponent
        editSettingState={editSettingState}
        setEditSettingState={setEditSettingState}
        fetchSetting={fetchSetting}
      />
    </Card>
  );
};

export default SettingComponent;
