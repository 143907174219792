import { Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';

export interface IUserItem {
  created_by: null;
  updated_by: null;
  deleted_by: null;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  id: string;
  name: string;
  fbId: string;
  email: string;
}
const UserItem: React.FC<{
  item: IUserItem | null;
}> = ({ item }) => {
  return (
    <Tooltip
      overlayStyle={{ whiteSpace: 'normal', maxWidth: 'none' }}
      title={
        <>
          <div>Name: {item?.name}</div>
          <div>Email: {item?.email}</div>
          <div>ID: {item?.fbId}</div>
        </>
      }
    >
      <Paragraph
        className="!mb-0"
        copyable={{
          text: item?.email || 'No email available'
        }}
      >
        {item?.name}
      </Paragraph>
    </Tooltip>
  );
};

export default UserItem;
