/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { FacebookConnectingResponse } from './type';
import { getFacebookConnecting } from '../../services/facebookServices';

type FacebookUserInformationState = {
  faceBookUserInformation: FacebookConnectingResponse | null;
  loading: boolean;
  error: string | null;
};

const initialState: FacebookUserInformationState = {
  faceBookUserInformation: null,
  loading: false,
  error: null
};

const facebookUserInformationSlice = createSlice({
  name: 'faceBookUserInformation',
  initialState,
  reducers: {
    setFacebookUserInformation(
      state,
      action: PayloadAction<FacebookConnectingResponse | null>
    ) {
      state.faceBookUserInformation = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setFacebookUserInformation, setLoading, setError } =
  facebookUserInformationSlice.actions;

export default facebookUserInformationSlice.reducer;

export const fetchFaceBookInformation =
  (navigate: (path: string) => void): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const resFBUser = await getFacebookConnecting();
      const fbUserInformation = resFBUser?.data
        ?.data as FacebookConnectingResponse;
      dispatch(setFacebookUserInformation(fbUserInformation));
    } catch (err) {
      console.log(err);
      // navigate('/');
      dispatch(setFacebookUserInformation(null));
    } finally {
      dispatch(setLoading(false));
    }
  };
