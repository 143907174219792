/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Alert,
  Avatar,
  Button,
  Drawer,
  message,
  Statistic,
  StatisticProps,
  Tabs,
  Typography
} from 'antd';
import React, { useEffect, useState } from 'react';

import {
  getDetailFacebookPages,
  stopSyncChattedUser,
  syncChattedUser
} from '../../services/facebookServices';
import dateTimeUTCConverter from '../../utils/dateTimeUTCConverter';
import TabPane from 'antd/es/tabs/TabPane';
import FacebookConversationList from './FacebookConversationList';
import {
  FileSyncOutlined,
  SyncOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import CampainsList from './CampainsList';
import ReportPageComponent from './ReportPageManage';
import DownloadBlockedUser from './DownloadBlockedUser';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import CountUp from 'react-countup';
import { errorMapping } from '../../utils/errorMapping';
const { Paragraph } = Typography;

interface IDataDetail {
  created_by: string;
  updated_by: string;
  deleted_by: null;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  id: string;
  pageId: string;
  name: string;
  category: string;
  pictureUrl: string;
  linkUrl: string;
  fanCount: number;
  isSyncChattedUsers: boolean;
  isFirstSyncFinish: boolean;
  nextPage: string;
  lastUpdatedTime: null;
  totalUsers: number;
  isHaveError: boolean;
  isStopSync: boolean;
}

const FacebookPageDetail: React.FC<any> = ({ uuid, setUuid }) => {
  const [dataDetail, setDataDetail] = useState<IDataDetail | null>(null);
  const [loadingFBPage, setLoadingFBPage] = useState<boolean>(false);
  const [syncing, setSyncing] = useState<boolean>(false);
  const [stoping, setStoping] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={(value as number) || 0} separator="," />
  );

  const fetchFacebookPageDetail = async (loading: boolean = true) => {
    if (!uuid) return;
    try {
      if (loading) setLoadingFBPage(true);
      const res = await getDetailFacebookPages(uuid);
      const facebookPages = res?.data?.data;
      setDataDetail(facebookPages);
      if (loading) setLoadingFBPage(false);
    } catch (error: any) {
      errorMapping(error);
      if (loading) setLoadingFBPage(false);
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchFacebookPageDetail();
    } else {
      setDataDetail(null);
    }
  }, [uuid]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (dataDetail?.isSyncChattedUsers) {
        fetchFacebookPageDetail(false);
      } else {
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [dataDetail?.isSyncChattedUsers, uuid]);

  const handleSyncChattedUser = async () => {
    if (!uuid) {
      return;
    }
    try {
      setSyncing(true);
      await syncChattedUser(uuid);
      message.success('Sync request successfully');
      setSyncing(false);
      fetchFacebookPageDetail(false);
    } catch (error: any) {
      errorMapping(error);
      setSyncing(false);
    }
  };

  const handleStopSyncChattedUser = async () => {
    if (!uuid) {
      return;
    }
    try {
      setStoping(true);
      await stopSyncChattedUser(uuid);
      message.success('Stop sync request successfully');
      setStoping(false);
      fetchFacebookPageDetail(false);
    } catch (error: any) {
      errorMapping(error);
      setStoping(false);
    }
  };

  return (
    <Drawer
      width="80vw"
      closable
      destroyOnClose
      title={<p>Facebook Page Detail</p>}
      placement="right"
      open={!!uuid}
      loading={loadingFBPage}
      onClose={() => setUuid(false)}
    >
      <Tabs className="mt-2" defaultActiveKey="1" destroyInactiveTabPane>
        <TabPane tab="Page Detail" key="1">
          <div className="flex gap-4 mt-3">
            <div className="w-[250px]">
              <Avatar size={150} src={dataDetail?.pictureUrl} />
            </div>
            <div className="w-full">
              <div className="flex gap-3 mb-4 ">
                <div className="w-[150px] text-[#969696]">ID</div>
                <div> {dataDetail?.pageId} </div>
              </div>

              <div className="flex gap-3 mb-4 ">
                <div className="w-[150px] text-[#969696]">Name</div>
                <div> {dataDetail?.name} </div>
              </div>

              <div className="flex gap-3 mb-4">
                <div className="w-[150px] text-[#969696]">Category</div>
                <div>{dataDetail?.category}</div>
              </div>

              <div className="flex gap-3 mb-4">
                <div className="w-[150px] text-[#969696]">Liked</div>
                <div>{dataDetail?.fanCount}</div>
              </div>

              <div className="flex gap-3 mb-4">
                <div className="w-[150px] text-[#969696]">Created Date</div>
                <div>{dateTimeUTCConverter(dataDetail?.created_at || '')}</div>
              </div>
            </div>
          </div>
        </TabPane>
        {/* {user?.roles[0].role_tag === 'page_manager' && (
          <> */}
        <TabPane tab="Page's Chatted User" key="2">
          <div className="mt-3">
            {!dataDetail && <SyncOutlined style={{ fontSize: '20px' }} spin />}
            {dataDetail?.isSyncChattedUsers ? (
              <Alert
                type="info"
                className="w-[280px]"
                description={
                  <>
                    <Statistic
                      // suffix={<SyncOutlined style={{ fontSize: '20px' }} spin />}
                      title="Number Of Users Synced"
                      value={dataDetail?.totalUsers || 0}
                      formatter={formatter}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={
                        <div className="flex gap-2 items-center">
                          <SyncOutlined style={{ fontSize: '20px' }} spin />
                          <UserSwitchOutlined />
                        </div>
                      }
                    />
                    {dataDetail?.nextPage && (
                      <div className="mt-2">
                        <div
                          style={{
                            color: 'rgba(0, 0, 0, 0.45)',
                            fontSize: '16px',
                            lineHeight: '22px',
                            marginBottom: '8px'
                          }}
                        >
                          Next Page
                        </div>
                        <div className="flex gap-2 items-center">
                          <FileSyncOutlined
                            style={{
                              color: 'rgb(63, 134, 0)',
                              fontSize: '24px'
                            }}
                          />
                          <Paragraph
                            className="!mb-0 text-[rgb(63, 134, 0)]"
                            copyable={{ text: dataDetail?.nextPage }}
                          >
                            {dataDetail?.nextPage?.slice(-15)}
                          </Paragraph>{' '}
                        </div>
                      </div>
                    )}
                    <Button
                      onClick={handleStopSyncChattedUser}
                      type="primary"
                      color="danger"
                      loading={stoping}
                      variant="solid"
                      className="mt-5"
                    >
                      Stop sync
                    </Button>
                  </>
                }
              />
            ) : !dataDetail?.isFirstSyncFinish &&
              dataDetail?.totalUsers === 0 ? (
              <Button
                loading={syncing}
                type="primary"
                onClick={handleSyncChattedUser}
              >
                Sync Data
              </Button>
            ) : (
              <>
                {dataDetail?.isHaveError && (
                  <Alert
                    className="mb-5"
                    description={
                      <>
                        <span className="mr-2">
                          There was an error during synchronization, you can use
                          the synchronized data or
                        </span>
                        <Button
                          loading={syncing}
                          size="small"
                          color="danger"
                          variant="solid"
                          onClick={handleSyncChattedUser}
                        >
                          Sync Data again
                        </Button>
                      </>
                    }
                    type="error"
                    showIcon
                    closable
                  />
                )}

                {dataDetail?.isStopSync && (
                  <Alert
                    className="mb-5"
                    description={
                      <>
                        <span className="mr-2">
                          You stopped the sync before it was complete, do you
                          want to
                        </span>
                        <Button
                          loading={syncing}
                          size="small"
                          type="dashed"
                          onClick={handleSyncChattedUser}
                        >
                          Sync Again
                        </Button>
                      </>
                    }
                    type="warning"
                    showIcon
                    closable
                  />
                )}

                <FacebookConversationList
                  uuid={uuid}
                  handleSyncChattedUser={handleSyncChattedUser}
                  syncing={syncing}
                />
              </>
            )}
          </div>
        </TabPane>
        <TabPane tab="Campaigns" key="3">
          <div className="mt-3">
            <CampainsList uuid={uuid} />
          </div>
        </TabPane>
        {/* </>
        )} */}

        <TabPane tab="Report" key="4">
          <div className="mt-3">
            <ReportPageComponent uuid={uuid} />
          </div>
        </TabPane>
        <TabPane tab="Block Users" key="5">
          <div className="mt-3">
            <DownloadBlockedUser uuid={uuid} />
          </div>
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default FacebookPageDetail;
