import React, { useEffect, useRef, useState } from 'react';
import { Upload, Modal, message, UploadFile, Spin } from 'antd';
import { PlaySquareTwoTone, PlusOutlined } from '@ant-design/icons';
import { uploadFile } from '../services/generalService';
import LoadingComponent from './LoadingComponent';
import { ACCEPTED_FILE_TYPES, FileTypes } from '../constants/common';
import { getAllowedExtensions } from '../utils/getAllowedExtensions';

interface UploadFileArrayProps {
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  maxFiles?: number;
  acceptedFileTypes?: FileTypes[];
}
const UploadFileArray: React.FC<UploadFileArrayProps> = ({
  fileList,
  setFileList,
  maxFiles = 1,
  acceptedFileTypes = [
    FileTypes.IMAGE,
    FileTypes.AUDIO,
    FileTypes.VIDEO,
    FileTypes.FILE
  ]
}) => {
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [previewContent, setPreviewContent] = useState<string | undefined>(
    undefined
  );
  const [previewType, setPreviewType] = useState<string>('');

  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef.current && !previewOpen && previewType === 'audio') {
      audioRef.current?.pause();
    }
  }, [previewOpen]);

  const handlePreview = async (file: UploadFile) => {
    const isImage = file.type?.startsWith('image');
    const isVideo =
      file.type?.startsWith('video') || file?.name?.endsWith('.mkv');
    const isAudio = file.type?.startsWith('audio');

    const fileUrl = file.url || URL.createObjectURL(file.originFileObj as File);

    if (isImage) setPreviewType('image');
    else if (isVideo) setPreviewType('video');
    else if (isAudio) setPreviewType('audio');
    else setPreviewType('file');

    setPreviewContent(fileUrl);
    setPreviewOpen(true);
  };

  const handleCancel = () => {
    setPreviewOpen(false);
    setPreviewContent(undefined);
  };

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append('file', file);
    setUploading(true);
    try {
      const response = await uploadFile(formData);
      const url = response?.data?.data;

      const uploadedFile: UploadFile = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: url,
        type: file.type,
        originFileObj: file
      };
      setUploading(false);
      setFileList((prevList) => [...prevList, uploadedFile]);
      onSuccess(response.data);
      message.success('Upload thành công!');
    } catch (error) {
      setUploading(false);
      console.error(error);
      onError(error);
      message.error('Upload thất bại và file đã bị xóa!');
    }
  };

  const uploadButton = (
    <div
      className={`flex rounded-[10px] cursor-pointer mt-2 w-full items-center justify-center h-[66px] border-[1px] border-solid border-['#d9d9d9]`}
    >
      {uploading ? (
        <Spin />
      ) : (
        <div className="flex gap-1">
          <PlusOutlined />
          <div>Tải File lên</div>
        </div>
      )}
    </div>
  );

  const handleRemove = (file: UploadFile) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  const beforeUpload = (file: File) => {
    const imageMaxSize = 10 * 1024 * 1024;
    const otherMaxSize = 25 * 1024 * 1024;

    let allowedTypes: string[] = [];

    if (acceptedFileTypes.includes(FileTypes.IMAGE)) {
      allowedTypes = [...allowedTypes, ...ACCEPTED_FILE_TYPES.IMAGE];
    }
    if (acceptedFileTypes.includes(FileTypes.AUDIO)) {
      allowedTypes = [...allowedTypes, ...ACCEPTED_FILE_TYPES.AUDIO];
    }
    if (acceptedFileTypes.includes(FileTypes.VIDEO)) {
      allowedTypes = [...allowedTypes, ...ACCEPTED_FILE_TYPES.VIDEO];
    }
    if (acceptedFileTypes.includes(FileTypes.FILE)) {
      allowedTypes = [...allowedTypes, ...ACCEPTED_FILE_TYPES.FILE];
    }

    const isImage = file.type.startsWith('image');

    const allowMKV =
      acceptedFileTypes.includes(FileTypes.VIDEO) && file.name.endsWith('.mkv');

    if (!allowedTypes.includes(file.type) && !allowMKV) {
      message.error(
        `Chỉ chấp nhận file ${getAllowedExtensions(acceptedFileTypes)}!`
      );
      return false;
    }

    if (isImage && file.size > imageMaxSize) {
      message.error('Kích thước ảnh tối đa là 10MB!');
      return false;
    }

    if (!isImage && file.size > otherMaxSize) {
      message.error('Kích thước file tối đa là 25MB!');
      return false;
    }

    return true;
  };

  return (
    <LoadingComponent loading={false}>
      <Upload
        accept="image/*,video/*,audio/*"
        listType="picture"
        fileList={fileList}
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        onPreview={handlePreview}
        iconRender={() => <PlaySquareTwoTone />}
        onRemove={handleRemove}
      >
        {fileList.length >= maxFiles ? null : uploadButton}
      </Upload>

      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        {previewType === 'image' && previewOpen && (
          <img alt="Preview" className="w-full mt-8" src={previewContent} />
        )}
        {previewType === 'video' && previewOpen && (
          <video controls className="w-full mt-8" src={previewContent} />
        )}
        {previewType === 'audio' && previewOpen && (
          <audio
            ref={audioRef}
            controls
            className="w-full mt-8"
            src={previewContent}
          />
        )}
        {previewType === 'file' && previewOpen && (
          <a href={previewContent} target="_blank" rel="noopener noreferrer">
            Tải xuống tệp
          </a>
        )}
      </Modal>
    </LoadingComponent>
  );
};

export default UploadFileArray;
