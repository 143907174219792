import React, { useState } from 'react';
import { Card, message } from 'antd';
import FacebookLogin from 'react-facebook-login';
import moment from 'moment';
import { connectFacebook } from '../../services/facebookServices';

interface Data {
  height: number;
  is_silhouette: boolean;
  url: string;
  width: number;
}
interface Picture {
  data: Data;
}

interface FacebookLoginResponse {
  name: string;
  email: string;
  picture: Picture;
  id: string;
  userID: string;
  expiresIn: number;
  accessToken: string;
  signedRequest: string;
  graphDomain: string;
  data_access_expiration_time: number;
}

const TestLoginComponent = () => {
  const [fetching, setFetching] = useState<boolean>(false);

  const responseFacebook = async (response: FacebookLoginResponse) => {
    connectFB(response);
  };

  const connectFB = async (response: FacebookLoginResponse) => {
    console.log('Response Facebook:', response);

    setFetching(true);
    try {
      const data = {
        accessToken: response.accessToken,
        dataAccessExpirationAt: moment()
          .add(response.data_access_expiration_time)
          .toISOString(),
        email: response.email,
        expireAt: moment().add(response.expiresIn, 'seconds').toISOString(),
        fbId: response.id,
        name: response.name,
        avatarUrl: response.picture.data.url
      };
      await connectFacebook(data);
      setFetching(false);
    } catch (err) {
      console.log(err);
      message.error('Facebook connect failed!');
      setFetching(false);
    }
  };

  return (
    <Card
      className="shadow-small min-h-[200px] flex justify-center items-center"
      loading={fetching}
    >
      <FacebookLogin
        appId="1389699085343958"
        // autoLoad
        callback={responseFacebook}
        fields="name,email,picture"
        // scope="public_profile,email,pages_messaging,pages_manage_metadata,pages_read_engagement,pages_show_list"
        scope="pages_messaging,pages_show_list"
        textButton="Connect with Facebook"
        cssClass="facebook-login-button"
      />
    </Card>
  );
};

export default TestLoginComponent;
