import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from 'chart.js';
import { getStatisticMessage } from '../../services/generalService';
import LoadingComponent from '../../components/LoadingComponent';
import moment from 'moment';
import { errorMapping } from '../../utils/errorMapping';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const ReportPageComponent: React.FC<any> = ({ uuid }) => {
  const [data, setData] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchReport = async () => {
    try {
      setLoading(true);
      const res = await getStatisticMessage(uuid);
      const mappingData = processData(res?.data?.data || []);
      setData(mappingData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorMapping(error);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  const processData = (
    data: Array<{ total: string; totalSuccess: string; date: string }>
  ) => {
    const monthsInYear = 12;

    const currentMonth = moment().month();
    const currentYear = moment().year();

    let extendedData = [...data];
    const existingMonths = data.map((item) => item.date);

    for (let i = 0; i < monthsInYear; i++) {
      const monthToAdd = moment()
        .month(currentMonth - i)
        .year(currentYear)
        .startOf('month');
      const formattedDate = monthToAdd.format('MM-YYYY'); // Định dạng tháng năm

      if (!existingMonths.includes(formattedDate)) {
        extendedData = [
          {
            total: '0',
            totalSuccess: '0',
            date: formattedDate
          },
          ...extendedData
        ];
      }
    }

    return extendedData;
  };

  const maxValue = useMemo(() => {
    const max = Math.max(...(data || []).map((x) => Number(x?.total)));
    return max ? max * 1.2 : 0;
  }, [data]);

  const chartData = {
    labels: (data || []).map((item) => item.date),
    datasets: [
      {
        label: 'Success',
        data: (data || []).map((item) => parseInt(item.totalSuccess)),
        backgroundColor: 'rgba(0, 186, 52, 1)',
        borderColor: 'rgba(0, 186, 52, 1)',
        borderWidth: 1,
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 0,
          bottomRight: 0
        }
      },
      {
        label: 'Total',
        data: (data || []).map((item) => parseInt(item.total)),
        backgroundColor: '#0085FF',
        borderColor: '#0085FF',
        borderWidth: 1,
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 0,
          bottomRight: 0
        }
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          }
        }
      }
    },
    scales: {
      x: {
        beginAtZero: true
      },
      y: {
        beginAtZero: true,
        suggestedMax: maxValue || 0
      }
    }
  };

  return (
    <LoadingComponent loading={loading}>
      <div>
        <h1>Report Total Message Sent</h1>
        <Bar data={chartData} options={options} />
      </div>
    </LoadingComponent>
  );
};

export default ReportPageComponent;
