/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';
import usePaginationConfig from '../../hooks/usePaginationConfig';
import useSortOrder from '../../hooks/useSortOrder';
import { message, Table } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import dateTimeUTCConverter from '../../utils/dateTimeUTCConverter';
import { DEFAULT_PAGINATE_SIZE } from '../../constants/common';
import { getListCampains } from '../../services/facebookServices';
import OpenSvg from '../../assets/svg/open';
import ElementWithTooltipComponent from '../../components/ElementWithTooltipComponent';
import { truncateText } from '../../utils/truncateText';
import { capitalizeWord } from '../../utils/capitalizeWord';
import { errorMapping } from '../../utils/errorMapping';

const CampainsList: React.FC<any> = ({ uuid }) => {
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const paginationConfig = usePaginationConfig(total, page, setPage);

  const { sortOrder, sortColumns, updateSortOrder, getSortParams } =
    useSortOrder<any>();

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    updateSortOrder(sorter);
  };

  const columns = [
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      align: 'center',
      key: 'updated_at',
      // ...sortColumns('updated_at'),
      render: (value: string) => dateTimeUTCConverter(value)
    },
    {
      title: 'Send Message Time',
      dataIndex: 'startSendMessage',
      align: 'center',
      key: 'startSendMessage',
      // ...sortColumns('updated_at'),
      render: (value: string) =>
        value ? dateTimeUTCConverter(value) : 'No Set'
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (value: string) => capitalizeWord(value)
    },
    {
      title: 'Content',
      dataIndex: 'message',
      key: 'message',
      render: (value: string, record: any) =>
        !value ? (
          <a
            href={record?.attachmentUrl}
            className="whitespace-nowrap"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex gap-1 items-center justify-start ">
              Open
              <OpenSvg />
            </div>
          </a>
        ) : (
          <ElementWithTooltipComponent tooltip={value}>
            {truncateText(value)}
          </ElementWithTooltipComponent>
        )
    },
    {
      title: 'Start Index',
      dataIndex: 'startUserIndex',
      align: 'center',
      key: 'startUserIndex',
      render: (value: number) => (value || value === 0 ? value : 'No Set')
    },
    {
      title: 'End Index',
      dataIndex: 'endUserIndex',
      align: 'center',
      key: 'endUserIndex',
      render: (value: number) => (value || value === 0 ? value : 'No Set')
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      key: 'total'
    },
    {
      title: 'Fail',
      dataIndex: 'totalFail',
      align: 'center',
      key: 'totalFail',
      render: (value: string) => (
        <div className="tag-wrapper flex justify-center">
          <div className={`tag tag-suspend`}>{value}</div>
        </div>
      )
    },
    {
      title: 'Success',
      dataIndex: 'totalSuccess',
      align: 'center',
      key: 'totalSuccess',
      render: (value: string) => (
        <div className="tag-wrapper flex justify-center">
          <div className={`tag tag-active`}>{value}</div>
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'isSent',
      align: 'center',
      key: 'isSent',
      render: (value: boolean) => (
        <div className="tag-wrapper flex justify-center">
          <div className={`tag tag-${value ? 'active' : 'suspend'}`}>
            {value ? 'Sent' : 'Not Sent'}
          </div>
        </div>
      )
      // (value ? 'Sent' : 'Not Sent')
    }
  ];

  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const res = await getListCampains(
        {
          page,
          limit: DEFAULT_PAGINATE_SIZE,
          order_by: 'created_at',
          sort_by: 'desc',
          ...getSortParams()
        },
        uuid
      );
      const mappingData = res?.data?.data;
      setData(mappingData?.items);
      setTotal(mappingData?.meta?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorMapping(error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, [page, sortOrder]);

  return (
    <div>
      <Table
        className="mt-3"
        columns={columns as any}
        dataSource={data}
        loading={loading}
        bordered
        onChange={handleTableChange}
        pagination={paginationConfig}
        rowClassName={() => 'cursor-pointer'}
      />
    </div>
  );
};

export default CampainsList;
