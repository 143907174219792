// hooks/useSortOrder.ts
import { useState } from 'react';
import { SorterResult } from 'antd/es/table/interface';

const useSortOrder = <T>() => {
  const [sortOrder, setSortOrder] = useState<
    SorterResult<T> | SorterResult<T>[]
  >({});

  //   useEffect(() => {
  //     const savedSortOrder = localStorage.getItem('sortOrder');
  //     if (savedSortOrder) {
  //       setSortOrder(JSON.parse(savedSortOrder));
  //     }
  //   }, []);

  const updateSortOrder = (
    newSortOrder: SorterResult<T> | SorterResult<T>[]
  ) => {
    setSortOrder(newSortOrder);
    // localStorage.setItem('sortOrder', JSON.stringify(newSortOrder));
  };

  const getSortParams = () => {
    if (!(sortOrder as SorterResult<T>)?.order) return {};

    return {
      order_by: (sortOrder as SorterResult<T>).field,
      sort_by:
        (sortOrder as SorterResult<T>).order === 'ascend' ? 'asc' : 'desc'
    };
  };

  const sortColumns = (key: string) => {
    return {
      sorter: true,
      sortOrder:
        (sortOrder as SorterResult<T>)?.field === key
          ? (sortOrder as SorterResult<T>).order
          : undefined
    };
  };

  return {
    sortOrder,
    sortColumns,
    updateSortOrder,
    getSortParams,
    setSortOrder
  };
};

export default useSortOrder;
