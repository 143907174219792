import { Avatar, Button, Card, message, Modal, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import AddFacebookPageComponent from './addFacebookPageModal';
import { fetchFaceBookInformation } from '../../redux/facebookUserInformation/facebookUserInformationSlice';
import { RootState, useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dateTimeUTCConverter from '../../utils/dateTimeUTCConverter';
import { SorterResult } from 'antd/es/table/interface';
// ,
import { DEFAULT_PAGINATE_SIZE } from '../../constants/common';
import useSortOrder from '../../hooks/useSortOrder';
import usePaginationConfig from '../../hooks/usePaginationConfig';
import {
  getImportedFacebookPages,
  removeFacebookPages
} from '../../services/facebookServices';
import OpenSvg from '../../assets/svg/open';
import FacebookPageDetail from './FacebookPageDetail';
import { errorMapping } from '../../utils/errorMapping';
import ElementWithTooltipComponent from '../../components/ElementWithTooltipComponent';
import Delete from '../../assets/svg/delete';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

// interface PageResponse {
//   id: string;
//   name: string;
//   access_token: string;
//   category: string;
//   picture: Picture;
//   fan_count: number;
//   link: string;
// }

// interface Picture {
//   data: Data;
// }

// interface Data {
//   height: number;
//   is_silhouette: boolean;
//   url: string;
//   width: number;
// }

const FacebookPageManageComponent = () => {
  const [isOpenAddPage, setIsOpenAddPage] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { faceBookUserInformation, loading } = useSelector(
    (state: RootState) => state.facebookUserInformation
  );

  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!faceBookUserInformation && !loading) {
      dispatch(fetchFaceBookInformation(navigate));
    }
  }, [dispatch, navigate]);

  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const paginationConfig = usePaginationConfig(total, page, setPage);
  const [fetching, setFetching] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string | null>(null);

  const { sortOrder, sortColumns, updateSortOrder, getSortParams } =
    useSortOrder<any>();

  const [actionState, setActionState] = useState<any>({
    isOpen: false,
    text: '',
    isLoading: false
  });

  const fetchFacebookPage = async () => {
    try {
      setFetching(true);
      const res = await getImportedFacebookPages({
        page,
        limit: DEFAULT_PAGINATE_SIZE,
        order_by: 'created_at',
        sort_by: 'desc',
        ...getSortParams()
      });
      const facebookPages = res?.data?.data;
      setData(facebookPages?.items || []);
      console.log(facebookPages?.meta?.total, 123);

      setTotal(facebookPages?.meta?.total);
      setFetching(false);
    } catch (error) {
      errorMapping(error);
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchFacebookPage();
  }, [page, sortOrder, isOpenAddPage]);

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    updateSortOrder(sorter);
  };

  const handleDelete = async (id: string) => {
    try {
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: true
      }));
      await removeFacebookPages(id);
      message.success('Remove successfully!');
      setActionState({
        isOpen: false,
        text: '',
        isLoading: false
      });
      fetchFacebookPage();
    } catch (err) {
      console.log(err);
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
      errorMapping(err);
    }
  };

  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'pictureUrl',
      key: 'pictureUrl',
      align: 'center',
      render: (value: string) => (
        <div className="flex justify-center">
          <Avatar size={35} src={value || ''} />
        </div>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      key: 'name'
      // ...sortColumns('chainId'),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      align: 'center',
      key: 'created_at',
      ...sortColumns('created_at'),
      render: (value: string) => dateTimeUTCConverter(value)
    },
    {
      title: 'Action',
      dataIndex: 'linkUrl',
      align: 'center',
      key: 'linkUrl',
      width: 100,
      render: (value: string, record: any) => (
        <div className="flex gap-3 justify-center">
          <ElementWithTooltipComponent tooltip="Open on new tab">
            <div
              onClick={(event) => {
                event.stopPropagation();
                window.open(value, '_blank', 'noopener,noreferrer');
              }}
              className="w-full flex justify-center"
            >
              <OpenSvg />
            </div>
          </ElementWithTooltipComponent>
          <ElementWithTooltipComponent tooltip="Remove from import">
            <div
              className="flex items-center justify-center  bg-[#EB3737] w-[25px] h-[25px] rounded-[5px]"
              onClick={(event) => {
                event.stopPropagation();
                setActionState({
                  isOpen: true,
                  text: 'remove',
                  isLoading: false,
                  id: record?.id
                });
              }}
            >
              <Delete />
            </div>
          </ElementWithTooltipComponent>
        </div>
      )
    }
  ];

  return (
    <Card>
      <div className="flex gap-2 items-center justify-between">
        <Title className="leading-none !mb-4 text-dark max-sm:w-full" level={3}>
          All Pages
        </Title>
        {user?.roles[0].role_tag === 'page_manager' && (
          <Button type="primary" onClick={() => setIsOpenAddPage(true)}>
            Add new page
          </Button>
        )}
      </div>
      <Table
        className="mt-3"
        columns={columns as any}
        dataSource={data}
        loading={fetching}
        bordered
        onChange={handleTableChange}
        pagination={paginationConfig}
        rowClassName={() => 'cursor-pointer'}
        onRow={(record, rowIndex) => ({
          onClick: () => {
            console.log(record, rowIndex);
            setUuid(record?.id);
          }
        })}
      />
      <AddFacebookPageComponent
        isModalOpen={isOpenAddPage}
        setIsModalOpen={setIsOpenAddPage}
      />

      <FacebookPageDetail uuid={uuid} setUuid={setUuid} />

      <Modal
        title={
          <div className="flex gap-3">
            <ExclamationCircleTwoTone style={{ fontSize: 20 }} />
            {actionState?.text.toLocaleUpperCase()}
          </div>
        }
        open={actionState?.isOpen}
        onOk={() => {
          switch (actionState?.text) {
            case 'remove':
              handleDelete(actionState?.id);
              break;
            default:
              handleDelete(actionState?.id);
              break;
          }
        }}
        confirmLoading={actionState?.isLoading}
        onCancel={() => {
          setActionState({
            isOpen: false,
            text: '',
            isLoading: false
          });
        }}
        okText="Confirm"
      >
        <p>{`Do you want to ${actionState?.text} this page?`}</p>
      </Modal>
    </Card>
  );
};

export default FacebookPageManageComponent;
