/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Form, InputNumber, message, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { validatePositiveNumber } from '../../utils/validatePositiveNumber';
import { updateAccount } from '../../services/accountService';
import { errorMapping } from '../../utils/errorMapping';

const UpdateMessagePerMonthComponent: React.FC<any> = ({
  editUserState,
  setEditUserState,
  fetchUser
}) => {
  const [updateUserForm] = Form.useForm();
  const [isSavingInitial, setIsSavingInitial] = useState<boolean>(false);

  const handleOk = () => {
    updateUserForm.submit();
  };

  const handleCancel = () => {
    updateUserForm.resetFields();
    setEditUserState(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      updateUserForm.submit();
    }
  };

  useEffect(() => {
    if (editUserState?.id) {
      updateUserForm.setFieldValue(
        'initial',
        editUserState?.numberOfMessagePerMonth
      );
    } else {
      updateUserForm.resetFields();
    }
  }, [editUserState]);

  const updateInitial = async (data: any) => {
    if (!editUserState?.id) {
      return;
    }
    try {
      setIsSavingInitial(true);
      const savingData = {
        numberOfMessagePerMonth: data
      };
      await updateAccount(editUserState?.id, savingData);
      fetchUser();
      setEditUserState(null);
      message.success('Update successfully!');
      setIsSavingInitial(false);
    } catch (error: any) {
      errorMapping(error);
      setIsSavingInitial(false);
    }
  };

  const onFinish = async (values: any) => {
    updateInitial(values?.initial);
  };

  return (
    <>
      <Modal
        title="Number Of Message Per Month"
        open={editUserState?.id}
        okText="Save"
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isSavingInitial
        }}
      >
        <Form
          name="inittial"
          layout="vertical"
          className="mt-5"
          form={updateUserForm}
          onFinish={onFinish}
          disabled={isSavingInitial}
        >
          <Form.Item
            name="initial"
            rules={[
              {
                required: true,
                message: 'Please input Initial!'
              },
              {
                validator: validatePositiveNumber
              }
            ]}
          >
            <InputNumber
              className="w-full"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) =>
                value?.replace(/\$\s?|(,*)/g, '') as unknown as number
              }
              prefix={isSavingInitial && <LoadingOutlined />}
              suffix="messages / month"
              placeholder="Amount"
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateMessagePerMonthComponent;
