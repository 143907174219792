import { Button, Card, message, Modal, Table } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import React, { useEffect, useState } from 'react';
import usePaginationConfig from '../../hooks/usePaginationConfig';
import { DEFAULT_PASSWORD } from '../../constants/common';
import useSortOrder from '../../hooks/useSortOrder';
import dateTimeUTCConverter from '../../utils/dateTimeUTCConverter';
import Title from 'antd/es/typography/Title';
import {
  activeAccount,
  deactiveAccount,
  deleteAccount,
  getAccounts,
  resetPassword
} from '../../services/accountService';
import AddUserComponent from './CreateUserModal';
import Delete from '../../assets/svg/delete';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import Swap from '../../assets/svg/swap';
import { capitalizeWord } from '../../utils/capitalizeWord';
import EditPencil from '../../assets/svg/editPencil';
import UpdateMessagePerMonthComponent from './UpdateMessagePerMonthComponent';
import ResetSvg from '../../assets/svg/reset';
import ElementWithTooltipComponent from '../../components/ElementWithTooltipComponent';
import { errorMapping } from '../../utils/errorMapping';
import { formatLocaleNumber } from '../../utils/formatLocaleNumber';

// interface PageResponse {
//   id: string;
//   name: string;
//   access_token: string;
//   category: string;
//   picture: Picture;
//   fan_count: number;
//   link: string;
// }

// interface Picture {
//   data: Data;
// }

// interface Data {
//   height: number;
//   is_silhouette: boolean;
//   url: string;
//   width: number;
// }

const UserManageComponet = () => {
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isOpenCreateUser, setIsOpenCreateUser] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const paginationConfig = usePaginationConfig(total, page, setPage);
  const [fetching, setFetching] = useState<boolean>(false);
  const [actionState, setActionState] = useState<any>({
    isOpen: false,
    text: '',
    isLoading: false
  });
  const [editUserState, setEditUserState] = useState<any>(null);

  const { sortOrder, updateSortOrder, getSortParams } = useSortOrder<any>();
  const fetchUser = async () => {
    try {
      setFetching(true);
      const res = await getAccounts({
        page,
        limit: 10,
        order_by: 'createdAt',
        sort_by: 'desc',
        ...getSortParams()
      });
      const projects = res?.data?.data?.items;
      setData(projects);
      setTotal(projects?.meta?.total);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      errorMapping(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: true
      }));
      await deleteAccount(id);
      message.success('Delete successfully!');
      setActionState({
        isOpen: false,
        text: '',
        isLoading: false
      });
      fetchUser();
    } catch (err) {
      console.log(err);

      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
      errorMapping(err);
    }
  };

  const handleActive = async (id: string) => {
    try {
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: true
      }));
      await activeAccount(id);
      message.success('Active successfully!');
      setActionState({
        isOpen: false,
        text: '',
        isLoading: false
      });
      fetchUser();
    } catch (err) {
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
      errorMapping(err);
    }
  };

  const handleResetPassword = async (id: string) => {
    try {
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: true
      }));
      await resetPassword(id);
      Modal.success({
        title: 'Reset password successfully',
        content: `New password: ${DEFAULT_PASSWORD}`,
        okButtonProps: {
          style: {
            backgroundColor: 'transparent',
            color: 'black',
            borderColor: 'rgba(232, 232, 232, 1)',
            marginTop: 15
          }
        }
      });
      setActionState({
        isOpen: false,
        text: '',
        isLoading: false
      });
      fetchUser();
    } catch (err) {
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
      errorMapping(err);
    }
  };

  const handleDeactive = async (id: string) => {
    try {
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: true
      }));
      await deactiveAccount(id);
      message.success('Deactive successfully!');
      setActionState({
        isOpen: false,
        text: '',
        isLoading: false
      });
      fetchUser();
    } catch (err) {
      setActionState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
      errorMapping(err);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [page, sortOrder]);

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    updateSortOrder(sorter);
  };

  const renderAction = (key: any) => {
    switch (key?.status) {
      case 'active':
        return (
          <ElementWithTooltipComponent tooltip="Deactive Account">
            <div
              className="flex items-center justify-center bg-[#FFEBEB] w-[25px] h-[25px] rounded-[5px]"
              onClick={() => {
                setActionState({
                  isOpen: true,
                  text: 'deactive',
                  isLoading: false,
                  id: key?.id
                });
              }}
            >
              <Swap />
            </div>
          </ElementWithTooltipComponent>
        );
      case 'suspend':
        return (
          <ElementWithTooltipComponent tooltip="Active Account">
            <div
              className="flex items-center justify-center bg-[#E5F8EA] w-[25px] h-[25px] rounded-[5px]"
              onClick={() => {
                setActionState({
                  isOpen: true,
                  text: 'active',
                  isLoading: false,
                  id: key?.id
                });
              }}
            >
              <Swap />
            </div>
          </ElementWithTooltipComponent>
        );
      default:
        return <div className="default-case">Default content here</div>;
    }
  };

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username'
      // ...sortColumns('chainId'),
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name'
      // ...sortColumns('chainId'),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      // ...sortColumns('createdAt'),
      render: (value: string) => dateTimeUTCConverter(value)
    },
    {
      title: 'Message per month',
      dataIndex: 'numberOfMessagePerMonth',
      key: 'numberOfMessagePerMonth',
      render: (value: string, record: any) => (
        <div className="flex gap-2 items-center">
          {formatLocaleNumber(value)}
          <ElementWithTooltipComponent tooltip="Edit Number of Message">
            <div
              onClick={() => {
                setEditUserState({
                  id: record?.id,
                  numberOfMessagePerMonth: record?.numberOfMessagePerMonth
                });
              }}
              className="h-[21px] w-[21px] flex items-center justify-center rounded-[4px] border border-dashed border-[#0CA7EE]"
            >
              <EditPencil />
            </div>
          </ElementWithTooltipComponent>
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      // ...sortColumns('chainId'),
      render: (status: string) => (
        <div className="tag-wrapper flex justify-center">
          <div className={`tag tag-${status.toLowerCase()}`}>
            {capitalizeWord(status)}
          </div>
        </div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 120,
      key: 'action',
      align: 'center',
      render: (_: any, record: any) => (
        <div
          className="flex gap-4 justify-center"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {renderAction(record)}
          <ElementWithTooltipComponent tooltip="Reset Password">
            <div
              className="flex items-center justify-center bg-slate-100 w-[25px] h-[25px] rounded-[5px]"
              onClick={() => {
                setActionState({
                  isOpen: true,
                  text: 'reset password',
                  isLoading: false,
                  id: record?.id
                });
              }}
            >
              <ResetSvg />
            </div>
          </ElementWithTooltipComponent>
          <ElementWithTooltipComponent tooltip="Delete Account">
            <div
              className="flex items-center justify-center  bg-[#EB3737] w-[25px] h-[25px] rounded-[5px]"
              onClick={() => {
                setActionState({
                  isOpen: true,
                  text: 'delete',
                  isLoading: false,
                  id: record?.id
                });
              }}
            >
              <Delete />
            </div>
          </ElementWithTooltipComponent>
        </div>
      )
    }
  ];

  return (
    <Card>
      <div className="flex justify-between gap-3 items-center mb-4">
        <Title className="leading-none !mb-0 text-dark max-sm:w-full" level={3}>
          Account Management
        </Title>
        <Button type="primary" onClick={() => setIsOpenCreateUser(true)}>
          Create new user
        </Button>
      </div>

      <Table
        columns={columns as any}
        dataSource={data}
        loading={fetching}
        bordered
        onChange={handleTableChange}
        pagination={paginationConfig}
        rowClassName={() => 'cursor-pointer'}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       console.log(record?.id);
        //     }
        //   };
        // }}
      />
      <AddUserComponent
        isModalOpen={isOpenCreateUser}
        setIsModalOpen={setIsOpenCreateUser}
        fetchUser={fetchUser}
      />
      <Modal
        title={
          <div className="flex gap-3">
            <ExclamationCircleTwoTone style={{ fontSize: 20 }} />
            {actionState?.text.toLocaleUpperCase()}
          </div>
        }
        open={actionState?.isOpen}
        onOk={() => {
          switch (actionState?.text) {
            case 'delete':
              handleDelete(actionState?.id);
              break;
            case 'active':
              handleActive(actionState?.id);
              break;
            case 'reset password':
              handleResetPassword(actionState?.id);
              break;
            default:
              handleDeactive(actionState?.id);
              break;
          }
        }}
        confirmLoading={actionState?.isLoading}
        onCancel={() => {
          setActionState({
            isOpen: false,
            text: '',
            isLoading: false
          });
        }}
        okText="Confirm"
      >
        <p>{`Do you want to ${actionState?.text} this account?`}</p>
      </Modal>
      <UpdateMessagePerMonthComponent
        editUserState={editUserState}
        setEditUserState={setEditUserState}
        fetchUser={fetchUser}
      />
    </Card>
  );
};

export default UserManageComponet;
