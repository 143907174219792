/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect, useState } from 'react';
import { Form, InputNumber, message, Modal, Select, UploadFile } from 'antd';
import { FileTypes } from '../../constants/common';
import { sendMessageAsCampaign } from '../../services/facebookServices';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import TextArea from 'antd/es/input/TextArea';
import MyDatePicker from '../../components/DatePicker';
import moment, { Moment } from 'moment';
import UploadFileArray from '../../components/UploadFile';
import { capitalizeWord } from '../../utils/capitalizeWord';
import { errorMapping } from '../../utils/errorMapping';

const SendMessageComponent: React.FC<any> = ({
  sendMessageState,
  setSendMessageState,
  setSelectedRowKeys,
  setUserIds
}) => {
  const [sendMessageForm] = Form.useForm();
  const [isSavingInitial, setIsSavingInitial] = useState<boolean>(false);
  const [type, setType] = useState<FileTypes>(FileTypes.TEXT);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // const [quillMessage, setQuillMessage] = useState('');

  // useEffect(() => {
  //   sendMessageForm.setFieldValue(
  //     'message',
  //     quillMessage === '<p><br></p>' ? '' : quillMessage
  //   );
  // }, [quillMessage]);

  const handleOk = () => {
    sendMessageForm.submit();
  };

  const handleCancel = () => {
    sendMessageForm.resetFields();
    setSendMessageState(null);
  };

  useEffect(() => {
    sendMessageForm.setFieldValue('attachmentUrl', fileList?.[0]?.url);
    sendMessageForm.validateFields(['attachmentUrl']);
  }, [fileList]);

  useEffect(() => {
    if (sendMessageState?.id) {
      sendMessageForm.resetFields();
      setType(FileTypes.TEXT);
      setFileList([]);
    }
  }, [sendMessageState]);

  const updateInitial = async (data: any) => {
    if (!sendMessageState?.id) {
      return;
    }
    try {
      setIsSavingInitial(true);
      const savingData: any = {
        message: data?.message,
        type
      };
      if (sendMessageState?.ids) {
        savingData.ids = (sendMessageState?.ids || []).join(',');
      } else {
        if (data?.startUserIndex) {
          savingData.startUserIndex = data?.startUserIndex - 1;
          savingData.endUserIndex = data?.endUserIndex - 1;
        }
      }
      if (data?.startSendMessage) {
        savingData.startSendMessage = data?.startSendMessage;
      }

      if (data?.attachmentUrl) {
        savingData.attachmentUrl = data?.attachmentUrl;
      }

      await sendMessageAsCampaign(sendMessageState?.id, savingData);
      setSendMessageState(null);
      // setQuillMessage('');
      if (savingData.ids) {
        setSelectedRowKeys(new Set([]));
        setUserIds(new Set([]));
      }
      message.success('Sent message');
      setIsSavingInitial(false);
    } catch (error: any) {
      errorMapping(error);
      setIsSavingInitial(false);
    }
  };

  const onFinish = async (values: any) => {
    updateInitial(values);
  };

  const disabledDate = (current: Moment | null) => {
    if (!current) return false;
    return current.isBefore(moment().startOf('day'));
  };

  const disabledTime = (current: Moment | null) => {
    if (!current) return {};

    const now = moment();
    if (current.isSame(now, 'day')) {
      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) => i).filter(
            (hour) => hour < now.hour()
          ),
        disabledMinutes: (selectedHour: number) =>
          selectedHour === now.hour()
            ? Array.from({ length: 60 }, (_, i) => i).filter(
                (minute) => minute < now.minute()
              )
            : []
      };
    }
    return {};
  };

  return (
    <>
      <Modal
        title="Message Information"
        open={sendMessageState?.id}
        okText="Send"
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isSavingInitial
        }}
      >
        <Form
          name="inittial"
          layout="vertical"
          className="mt-5"
          form={sendMessageForm}
          onFinish={onFinish}
          disabled={isSavingInitial}
          initialValues={{
            type: FileTypes.TEXT
          }}
        >
          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: 'Please select type of message!'
              }
            ]}
          >
            <Select
              value={type}
              onChange={(value: FileTypes) => {
                setType(value);
                setFileList([]);
              }}
            >
              {Object.values(FileTypes)
                .filter((x) => x !== FileTypes.FILE)
                .map((item) => (
                  <Select.Option key={item} value={item}>
                    {capitalizeWord(item)}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          {type === FileTypes.TEXT ? (
            <Form.Item
              name="message"
              label="Message"
              rules={[
                {
                  required: true,
                  message: 'Please input Message!'
                }
              ]}
            >
              {/* <ReactQuill
              theme="snow"
              value={quillMessage}
              style={{
                height: '250px',
                marginBottom: '35px',
                paddingBottom: '10px'
              }}
              onChange={setQuillMessage}
              placeholder="Nhập nội dung tin nhắn..."
            /> */}
              <TextArea />
            </Form.Item>
          ) : (
            <Form.Item
              name="attachmentUrl"
              label="Attachment"
              rules={[
                {
                  required: true,
                  message: 'Please upload attachment!'
                }
              ]}
            >
              <UploadFileArray
                acceptedFileTypes={[type]}
                fileList={fileList}
                setFileList={setFileList}
              />
            </Form.Item>
          )}

          {!sendMessageState?.ids && (
            <>
              <Form.Item
                label="Start Index"
                name="startUserIndex"
                rules={[
                  {
                    required: true,
                    message: 'Please input start index!'
                  },
                  {
                    type: 'number',
                    min: 1,
                    max: sendMessageState?.total,
                    message: `The starting index must be between 1 and ${sendMessageState?.total}!`
                  }
                ]}
              >
                <InputNumber className="w-full" />
              </Form.Item>

              <Form.Item
                label="End Index"
                name="endUserIndex"
                rules={[
                  {
                    required: true,
                    message: 'Please input end index!'
                  },
                  {
                    type: 'number',
                    min: 2,
                    max: sendMessageState?.total + 1,
                    message: `The ending index must be between 1 and ${sendMessageState?.total + 1}!`
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startValue = getFieldValue('startUserIndex');
                      if (value <= startValue) {
                        return Promise.reject(
                          new Error(
                            'The ending index must be greater than the starting index.!'
                          )
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <InputNumber className="w-full" />
              </Form.Item>
            </>
          )}

          <Form.Item
            name="startSendMessage"
            label="Time to sent message (skip if you want to send now)"
          >
            <MyDatePicker
              className="w-full"
              showTime={{
                format: 'HH:mm'
              }}
              format="HH:mm DD-MM-YYYY"
              disabledDate={disabledDate}
              disabledTime={disabledTime}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SendMessageComponent;
