import axiosInstance from '../axios/axiosInstance';
import {
  ChangePasswordRequest,
  LoginRequest
} from '../pages/Login/type/LoginTypes';

export const getProfile = () => {
  return axiosInstance.get('/v1/auth/my-profile');
};

export const login = (data: LoginRequest) => {
  return axiosInstance.post('/v1/auth/login', data);
};

export const changePassword = (data: ChangePasswordRequest, token: string) => {
  return axiosInstance.put('/v1/auth/change-password', data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
