import React from 'react';

const PrivacyPolicyComponent = () => {
  return (
    <div className={`container mx-auto my-[30px]`}>
      <div className="px-5 py-3">
        <h1 className="text-black font-bold text-lg mb-3">
          Chính Sách Quyền Riêng Tư
        </h1>
        <h2 className="text-black font-bold text-lg mb-3">
          Ngày có hiệu lực:
          <span className="text-black ml-1">[19/12/2024]</span>
        </h2>
        <p className="text-black text-base mb-3">
          Chúng tôi cam kết bảo vệ quyền riêng tư của bạn. Chính sách này giải
          thích cách chúng tôi thu thập, sử dụng và bảo vệ thông tin cá nhân của
          bạn khi sử dụng ứng dụng seedingTool.
        </p>
        <ol className="list-decimal pl-5 space-y-4">
          <div>
            <span className="font-bold text-lg">1. </span>
            <h2 className="font-bold text-lg inline">
              Thông Tin Chúng Tôi Thu Thập
            </h2>
            <p className="ml-4 mt-2 mb-2">
              Chúng tôi có thể thu thập các loại thông tin sau:
            </p>
            <ul className="list-disc pl-8 space-y-2">
              <li>
                <strong>Thông tin cá nhân:</strong> Tên, địa chỉ email, ảnh hồ
                sơ, và bất kỳ thông tin nào bạn cung cấp qua tài khoản Facebook.
              </li>
              <li>
                <strong>Dữ liệu kỹ thuật:</strong> Địa chỉ IP, loại trình duyệt,
                và thông tin về thiết bị.
              </li>
              <li>
                <strong>Thông tin hoạt động:</strong> Dữ liệu liên quan đến cách
                bạn tương tác với ứng dụng của chúng tôi.
              </li>
            </ul>
          </div>
          <div>
            <span className="font-bold text-lg">2. </span>
            <h2 className="font-bold text-lg inline">
              Mục Đích Sử Dụng Thông Tin
            </h2>
            <ul className="list-disc pl-8 space-y-2 mt-2">
              <li>Cung cấp và cải thiện dịch vụ.</li>
              <li>Cá nhân hóa trải nghiệm của bạn.</li>
              <li>
                Liên hệ với bạn về các cập nhật hoặc vấn đề liên quan đến dịch
                vụ.
              </li>
            </ul>
          </div>
          <div>
            <span className="font-bold text-lg">3. </span>
            <h2 className="font-bold text-lg inline">Chia Sẻ Thông Tin</h2>
            <p className="mt-2 mb-2">
              Chúng tôi không bán, trao đổi, hoặc chia sẻ thông tin cá nhân của
              bạn với bên thứ ba ngoại trừ:
            </p>
            <ul className="list-disc pl-8 space-y-2">
              <li>Tuân thủ yêu cầu pháp lý hoặc lệnh của tòa án.</li>
              <li>Với sự đồng ý của bạn.</li>
            </ul>
          </div>
          <div>
            <span className="font-bold text-lg">4. </span>
            <h2 className="font-bold text-lg inline">
              Lưu Trữ Và Bảo Mật Dữ Liệu
            </h2>
            <p className="mt-2">
              Chúng tôi lưu trữ thông tin của bạn một cách an toàn trên các máy
              chủ được bảo vệ bằng các biện pháp bảo mật phù hợp.
            </p>
          </div>
          <div>
            <span className="font-bold text-lg">5. </span>
            <h2 className="font-bold text-lg inline">Quyền Của Bạn</h2>
            <ul className="list-disc pl-8 space-y-2 mt-2">
              <li>Yêu cầu xem, sửa, hoặc xóa thông tin cá nhân của mình.</li>
              <li>
                Rút lại sự đồng ý bất kỳ lúc nào (có thể ảnh hưởng đến việc sử
                dụng ứng dụng).
              </li>
            </ul>
          </div>
          <div>
            <span className="font-bold text-lg">6. </span>
            <h2 className="font-bold text-lg inline">Thay Đổi Chính Sách</h2>
            <p className="mt-2">
              Chúng tôi có thể cập nhật chính sách này theo thời gian. Mọi thay
              đổi sẽ được thông báo tại trang này, kèm theo ngày cập nhật.
            </p>
          </div>
          <div>
            <span className="font-bold text-lg">7. </span>
            <h2 className="font-bold text-lg inline">Liên Hệ</h2>
            <p className="mt-2">
              Nếu bạn có bất kỳ câu hỏi nào về chính sách quyền riêng tư này,
              vui lòng liên hệ với chúng tôi:
            </p>
            <ul className="list-disc pl-8 mt-2">
              <li>
                <a
                  href="mailto:vuhoangminhquan2002@gmail.com"
                  className="text-blue-600"
                >
                  Email: vuhoangminhquan2002@gmail.com
                </a>
              </li>
            </ul>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicyComponent;
